<template lang="pug">
  v-card
    v-toolbar(
      dark
      :color="!editMode ? 'green' : 'yellow darken-3'"
      dense
      flat
    )
      span {{ !editMode ? 'New Incubator' : 'Edit Incubator' }}
    v-container
      v-row
        v-col(cols="12").mt-2
          v-text-field(
            label="Code"
            dense
            v-model="code"
            :error-messages="incubatorStorePostErrors.code"
          )
        v-col(cols="12")
          v-text-field(
            label="Location"
            dense
            v-model="location"
            :error-messages="incubatorStorePostErrors.location"
          )
        v-col(v-if="!editMode" cols="12")
          v-btn(
            dark
            dense
            color="green"
            block
            @click="store"
            :loading="incubatorStorePosting"
          )
            span save
        v-col(v-else cols="12")
          v-row
            v-col(cols="6")
              v-btn(
                dark
                dense
                color="yellow darken-3"
                block
                @click="update"
                :loading="incubatorStorePosting"
              )
                span Update
            v-col(cols="6")
              v-btn(
                dark
                dense
                color="red"
                block
                @click="editMode = false"
              )
                span Cancel
</template>
<script>
import incubatorRepository from '@/repositories/incubator.repository'
import VueRequestHandler from '@/libs/classes/VueRequestHandler.class'
import { requestVars } from '@/libs/api-helper.extra'

const [incubatorStoreVars, incubatorStoreVarNames] = requestVars({ identifier: 'incubator-store', request: 'post', hasData: false })
const incubatorStoreHandler = new VueRequestHandler(null, incubatorStoreVarNames)

const inputVars = () => ({
  id: null,
  code: null,
  location: null,
})

export default {
  name: 'CreateIncubator',
  props: {
    defaultData: Object,
  },
  data () {
    return {
      ...inputVars(),
      ...incubatorStoreVars,
    }
  },
  computed: {
    editMode: {
      get () {
        return !this.$objectEmpty(this.defaultData)
      },
      set (val) {
        if (val) return
        this.$emit('update:default-data', {})
      },
    },
  },
  watch: {
    code (val) {
      if (!val) return
      this.code = val.toUpperCase()
    },
    defaultData () {
      const input = inputVars()
      this.$populateInputData({ input })
    },
  },
  methods: {
    async store () {
      const confirm = await this.confirmDialog()
      if (!confirm) return
      const { id, ...data } = this.$getInputData(inputVars())
      const handler = incubatorStoreHandler
      const repository = incubatorRepository.store
      handler.setVue(this)
      handler.execute(repository, [data])
    },
    async update () {
      const confirm = await this.confirmDialog(false)
      if (!confirm) return
      const { id, ...data } = this.$getInputData(inputVars())
      const handler = incubatorStoreHandler
      const repository = incubatorRepository.update
      handler.setVue(this)
      handler.execute(repository, [id, data])
    },
    confirmDialog (store = true) {
      if (store) {
        const config = {
          title: 'Store',
          icon: 'mdi-content-save',
          color: 'green',
        }
        return this.$confirm('Are you sure you want to add this incubator?', config)
      }
      const config = {
        title: 'Update',
        icon: 'mdi-pencil',
        color: 'yellow darken-3',
      }
      return this.$confirm('Are you sure you want to update this incubator?', config)
    },
  },
}
</script>